@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


html,
body {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  
  background: #0D0D0E;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
a {
  color: inherit;
  text-decoration: none;
}
* {
  color:  #FFFFFF;
  font-family: 'Instrument Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}